import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from 'prop-types';

import { APP_PAGES } from "../../../constants/app-pages";

import { updateShowTourGuide } from "../../../redux/actions/tour-guide.action";

import StorageUtil from "../../../util/storage.util";

import UserService from "../../../services/user.service";

import PmivrTooltip, { TOOLTIP_POSITIONS } from "../../../components/common/tooltip/pmivr-tooltip";

/**
 * The component for user avatar used in footer
 * @returns {React.Component} Html element to render 
 */
const UserAvatar = ({ user }) => {
  // navigating to pages
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { deploymentEnvironment } = useSelector(state => state.client);
  // logs out and redirects to login page
  const logout = () => {
    StorageUtil.clearStorage();
  };

  /**
   * Navigating to a page on action of click of options
   * @param {Object} event - event triggered by action
   * @param {string} page - link of the page to navigate
   */
  const goToPage = (event, page) => {
    // preventing the default href functionality
    event.preventDefault();
    navigate(page);
  }

  // Extract the first letters of the user's first and last names
  const firstNameInitial = user?.firstName ? user.firstName.charAt(0) : "";
  const lastNameInitial = user?.lastname ? user.lastname.charAt(0) : "";

  // Combine the first name initial and last name initial
  const initials = `${firstNameInitial}${lastNameInitial}`;

  // updates the tour guide state
  const toggleTourGuideDisplay = () =>{
    dispatch(updateShowTourGuide({ showGuide: true }));
  }

  return (
    <div className="pmivr-user-avatar">
      <div className="dropdown px-2 footer-dropdown-menu">
        {
          user ?
            <PmivrTooltip message={user?.firstName} position={TOOLTIP_POSITIONS.TOP}>
              <a className="dropdown-toggle " href="/#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
                aria-expanded="false">
                <i className="bi bi-circle-fill" style={{ position: "relative" }} l>
                  <span className="avatar-initials">{initials}</span>
                </i>
              </a>
            </PmivrTooltip> :
            <a className="dropdown-toggle " href="/#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
              aria-expanded="false">
              <i className="bi bi-person-circle"></i>
            </a>
        }
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <li className="px-3 m-2 border-bottom pb-3 pmivr-title small">
            {user?.email}
          </li>

          {UserService.hasPermission() && (
            <>
              <li className="m-2 border-bottom pb-2">
                <a onClick={(e)=>goToPage(e,APP_PAGES.LOGS)} className="dropdown-item" href="/#">
                  <i className="bi bi-telephone-outbound"></i> Call Logs
                </a>
              </li>
              <li className="m-2 border-bottom pb-2">
                <a onClick={(e)=>goToPage(e,APP_PAGES.SETTINGS)} className="dropdown-item" href="/#">
                  <i className="bi bi-gear"></i> Settings
                </a>
              </li>
            </>
          )}
          <li className="m-2 border-bottom pb-2">
              <button onClick={toggleTourGuideDisplay} className="dropdown-item">
                <i className="bi bi-info-circle"></i> Tour Guide
              </button>
          </li>
          <li className="m-2">
            <Link to={APP_PAGES.LOGIN} onClick={logout} className="dropdown-item logout-text">
              <i className="bi bi-power"></i> Log Out
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

UserAvatar.propTypes = {
  // Details of current user who has logged in.
  user: PropTypes.object
}

export default UserAvatar;