import moment from "moment";

class DateUtil {
    /**
     * Convert date string to readable string format (like data string of mongo db)
     * @param {string} date date string 
     * @returns {string}
     */
    static toDateString(date) {
        const dateObj = new Date(date);
        return dateObj.toDateString();
    };

    /**
     * Parses a date string in the format "DD-MM-YYYY HH:mm" and returns a JavaScript Date object.
     * @param {string} dateString - The date string in the format "DD-MM-YYYY HH:mm".
     * @returns {Date} - A JavaScript Date object representing the parsed date and time.
     * Example: 
     * const date = parseDateString("01-10-2024 14:30");
     * console.log(date); // Output: Wed Oct 01 2024 14:30:00 GMT+0000 (Coordinated Universal Time)
     */
    static parseDateString(dateString) {
        return moment(dateString, "DD-MM-YYYY HH:mm").toDate();
    }
}

export default DateUtil;