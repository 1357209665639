import { useEffect, useState, useRef, useImperativeHandle, forwardRef } from "react";

import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { MESSAGES, TOOLTIP } from "../../../constants/messages";

import UserService from '../../../services/user.service';

import PmivrTooltip, { TOOLTIP_POSITIONS } from "../../../components/common/tooltip/pmivr-tooltip";

/**
 * Dialog box to upload xml file done in flow (draft or publish doc)
 * @param {Object} props props data from parent component
 * @returns {React.Component} Html code to render dialog
 */
const UploadFileDialog = forwardRef((props, ref) => {
    const { selectedFlowType, uploadBaseFlow, setIsSplitView } = props;

    // using the open method from the snackbar component
    const snackbarRef = useRef();

    // variable to show and hide the dialog box
    const [dialogProps, setDialogProps] = useState({ showDialog: false });

    useEffect(() => {
        const init = async () => {
            try {
            } catch (err) {
                // opening the snackbar
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
        init();
    }, []);

    // exposing functions to parent component
    useImperativeHandle(ref, () => ({
        open() {
            setDialogProps((dialogProps) => ({ ...dialogProps, showDialog: true }));
        },
        close: closeDialog
    }));

    // validating the formik fields
    const validate = Yup.object({
        comments: Yup.string().required(MESSAGES.ERR.FIELD_REQUIRED),
        file: Yup.mixed().required(MESSAGES.ERR.XML_FILE_REQUIRED)
            .test('is-valid-type', MESSAGES.ERR.INVALID_XML_FILE, (value) => {
                if (value) {
                    if (['text/xml'].includes(value?.type) && value?.name?.endsWith(".xml")) {
                        return true;
                    } else {
                        return false;
                    }
                }
            })
    });

    // function to close the upload file dialog box
    const closeDialog = () => {
        setIsSplitView(false);
    }

    return (
        <div className="upload-file-container">
            <h4 className="p-4 border-bottom">{selectedFlowType?.name}</h4>
            <Formik initialValues={{ file: null, flowTypeId: null, name: null, comments: '' }}
                validationSchema={validate} onSubmit={uploadBaseFlow}>
                {({ setFieldValue, values, errors, setErrors }) => (
                    <Form>
                        <div className="pmivr-title pt-2 text-start row p-4 pb-0" >
                            <label className="pmivr-label pb-2">Mention the changes that has been made in this version</label>
                            {UserService.hasPermission() && (
                                <div className="form-group template-textarea mb-2">
                                    <textarea id={`comments-${selectedFlowType.flowTypeId}`} name="comments"
                                        className="form-group doc-comments pmivr-input p-2" rows="3"
                                        value={values.comments}
                                        placeholder="Enter the changes"
                                        onChange={(event) => {
                                            setFieldValue("comments", event.target.value);
                                            // resetting the event target value as some browsers 
                                            // did not clear the event target for input file
                                            event.target.value = '';
                                        }}
                                    />
                                    <ErrorMessage name="comments" component="div"
                                        className="field-error error pt-1" />
                                </div>
                            )}
                        </div>

                        {UserService.hasPermission() && (
                            <div className="text-start pt-3 p-4 pt-0">
                                <div className="pmivr-drop-audio-file template-drop-audio-file">
                                    <PmivrTooltip message={TOOLTIP.INPUT.UPLOAD_VOICE_FILE}>
                                        <div className="choose-file">
                                            <a href="/#"><i class="bi bi-upload"></i></a> <br />
                                            <a href="/#">Click to upload  </a>
                                            or{" "} drag and drop here <br />
                                            <span className="pt-0"> <b>XML</b> file only </span>
                                            <input
                                                id={`file-${selectedFlowType.flowTypeId}`}
                                                className="form-control form-control-lg"
                                                type="file" accept=".xml"
                                                onChange={(event) => {
                                                    setFieldValue("file", event.currentTarget.files[0]);
                                                    setFieldValue("flowTypeId", selectedFlowType.flowTypeId);
                                                    setFieldValue("name", selectedFlowType.name);
                                                    // resetting the event target value as some browsers 
                                                    // didnot clear the event target for input file
                                                    event.target.value = null;
                                                }}
                                                placeholder="Drop audio file here or Browse"
                                            />
                                        </div>
                                    </PmivrTooltip>
                                </div>
                                <div className="pmivr-upload-file py-3">
                                    <ErrorMessage name="file" component="div" className="field-error error" />
                                    <div className="file-name mb-2">File Path : <span className="file-path"> {values?.file?.name}</span></div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <PmivrTooltip message={TOOLTIP.CANCEL} position={TOOLTIP_POSITIONS.TOP}>
                                        <button type="button"
                                            className="pmivr-btn-cancel"
                                            onClick={closeDialog}>
                                            Cancel
                                        </button>
                                    </PmivrTooltip>
                                    <PmivrTooltip message={"Upload the selected flow file"} position={TOOLTIP_POSITIONS.TOP}>
                                        <button type="submit"
                                            className="pmivr-btn-app ms-2" >
                                            Upload
                                        </button>
                                    </PmivrTooltip>
                                </div>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
        </div >
    )
});

UploadFileDialog.propTypes = {
    // function to upload the xml file
    uploadBaseFlow: PropTypes.func,
    // flow type (standard ivr, aaivr etc.)
    selectedFlowType: PropTypes.string
}

export default UploadFileDialog;