import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";

import { MESSAGES } from "../../constants/messages";
import { APP_PAGES } from "../../constants/app-pages";
import { envConfig } from "../../environment";

import { updateBusinessCode, updateDeploymentEnv, updateIsVerifiedClient } from "../../redux/actions/client.action";

import { PmivrDialog } from "../../components/common/dialog/pmivr-dialog";
import PmivrSnackbar from "../../components/common/dialog/pmivr-snackbar";

import AppUtil from "../../util/app.util";
import StorageUtil from "../../util/storage.util";

import ClientService from "../../services/client.service";
import FlowService from "../../services/flow.service";
import UserService from "../../services/user.service";
import ConfigService from "../../services/config.service";

/**
 * User can search the businessCode / client
 * If existing client, then will be redirected to page showing its flow versions history, and
 * If new client, then will be redirected to wizard page for onboarding the new client
 * @returns {React.Component} Html element to render 
 */
const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // using the open method from the snackbar component
  const snackbarRef = useRef();
  // recent businessCode and confirm dialog box
  const [uiState, setUiState] = useState({ showRecentBusinessCodeLoader: false, showConfirmDialog: false });
  // array of recently updated businessCode
  const [recentlyUpdatedBusinessCode, setRecentlyUpdatedBusinessCode] = useState([]);
  // deployment environment properties
  const [deploymentEnvironment, setDeploymentEnvironment] = useState({
    environment: [], selectedEnvironment: '', isDeploymentEnvironmentsExists: false
  });
  // formik values for form to enter businessCode name
  const formik = useFormik({
    initialValues: {
      businessCode: "",
      environment: ""
    },
    onSubmit: (values) => {
      getClientFlowStates(values);
    },
  });

  useEffect(() => {
    onInit();
  }, []);

  useEffect(() => {
    loadRecentlySearches();
  }, [deploymentEnvironment.selectedEnvironment]);

  // loading the recent searches, as per user email and the deployment environment selected
  const loadRecentlySearches = async () => {
    // if there are options for data centers selection, then need to update the redux with selected data center
    dispatch(updateDeploymentEnv({ deploymentEnvironment: deploymentEnvironment.selectedEnvironment }));
    const currentUser = UserService.getCurrentUser();
    if (currentUser?.email) {
      try {
        setUiState({ ...uiState, showRecentBusinessCodeLoader: true });
        // getting the clients recently updated by the user (latest history of clients updated by user).
        const email = currentUser?.email;
        // load recent searches as per selected environment
        const businessCode = await ClientService.getRecentSearches(email);
        setRecentlyUpdatedBusinessCode(businessCode.data);
        setUiState({ ...uiState, showRecentBusinessCodeLoader: false });
      } catch (error) {
        setUiState({ ...uiState, showRecentBusinessCodeLoader: false });
        if (snackbarRef?.current) {
          snackbarRef.current.open(MESSAGES.ERR.RECENTLY_UPDATED_BUSINESS_CODE_NOT_FOUND);
        }
      }
    }
  }

  const onInit = async () => {
    // clear the environment from redux when you come on home page
    dispatch(updateDeploymentEnv({ deploymentEnvironment: '' }));
    setLastVisitedPage();
    // loading the configured deployment environments
    loadEnvironments();
  };

  // loading the configured deployment environments and preparing the select options
  const loadEnvironments = async () => {
    const configuredEnv = await ConfigService.getDeploymentEnvironments();
    if (configuredEnv?.length) {
      let options = [{ text: 'Select Environment', value: '', disabled: true }];
      configuredEnv.map((env) => {
        if (env?.key) {
          options.push({ text: env?.name, value: env?.key, disabled: false });
        }
      });
      setDeploymentEnvironment({ ...deploymentEnvironment, environment: options, isDeploymentEnvironmentsExists: true });
    }
  }

  const setLastVisitedPage = () => {
    // Navigating to the lastUrl (on which user was working), after login (regaining session).
    const lastUrl = UserService.getLastVisitedUrl();
    if (lastUrl) {
      // moving to the last visited page after regaining session
      AppUtil.redirectTo(lastUrl);
      // clearing the value (last visited url) from local storage
      StorageUtil.removeStorageValues([StorageUtil.STORAGE.LAST_URL]);
    }
  }

  /**
   * Navigates to businessCode page 
   */
  const naviagateToClientFlows = (businessCode) => {
    return navigate(`${APP_PAGES.CLIENT_FLOWS}/${businessCode}`);
  }

  /**
   * Checks the cofiguration for the client.
   * If config found, then load the flow states for that client.
   * Otherwise, create new flow for client
   * @param {{businessCode,selectedEnvironment}} data Searched businessCode & Selected Environment.
   */
  const getClientFlowStates = async (data) => {
    let isOnboardedClient = false;
    const businessCode = data.businessCode ? data.businessCode.trim().toLowerCase() : null;
    const selectedEnvironment = deploymentEnvironment.isDeploymentEnvironmentsExists
      ? (data.selectedEnvironment ? data.selectedEnvironment : deploymentEnvironment.selectedEnvironment) : "";
    formik.setFieldValue("businessCode", businessCode);
    formik.setFieldValue("environment", selectedEnvironment);

    // checking if environment has been selected
    const isEnvironmentSelected = deploymentEnvironment?.isDeploymentEnvironmentsExists
      ? AppUtil.isValueValid(selectedEnvironment) : true;

    if (!isEnvironmentSelected) {
      // if deployment environment options exists, but the environment is not selected
      snackbarRef.current.open(MESSAGES.ERR.SELECT_ENVIRONMENT);
    } else if (businessCode && isEnvironmentSelected) {
      try {
        const isVerifiedClient = await ClientService.getClientVerification(businessCode);
        // verify the client to check if it exist in oracle.
        if (isVerifiedClient?.data) {
          // update recent searched business code in the database
          ClientService.updateRecentSearch(businessCode);
          // update the redux
          dispatch(updateIsVerifiedClient({ isVerifiedClient: isVerifiedClient?.data }));
          // if client exists then check for businessCode in ivr platform
          const clientInfo = await ClientService.getBusinessCodeInfo({ businessCode });
          if (clientInfo && Object.keys(clientInfo).length) {
            isOnboardedClient = true;
          }
          const basicFlowInfo = {};
          basicFlowInfo.businessCode = businessCode;
          // adding the businessCode being currently searched in localStorage for further use
          FlowService.setBasicFlowInfo(basicFlowInfo);
          // updating info in redux state
          dispatch(updateBusinessCode({ businessCode }));
          // ask to user if he want to create new client
          if (!isOnboardedClient) {
            setUiState({ ...uiState, showConfirmDialog: true });
          } else {
            naviagateToClientFlows(businessCode);
          }
        } else {
          // not able to find the client configs keys for the client in DB
          snackbarRef.current.open((isEnvironmentSelected && AppUtil.isValueValid(selectedEnvironment))
            ? MESSAGES.ERR.BUSINESS_CODE_NOT_EXISTS_ENVIRONMENT
            : MESSAGES.ERR.BUSINESS_CODE_INVALID);
        }
      } catch (err) {
        // opening the snackbar
        snackbarRef.current.open(MESSAGES.ERR.BUSINESS_CODE_INVALID);
      }
    }
  };

  return (
    <>
      <PmivrSnackbar ref={snackbarRef} />
      <div className="pmivr-search-business-code ">
        <div className="form-group pt-2">
          <div className="text-center mb-5">
            <img src={`${envConfig.PUBLIC_URL}/images/logo/paymentus-logo.png`} alt="Paymentus" />
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              {deploymentEnvironment?.isDeploymentEnvironmentsExists && (
                <>
                  <div className="col-md-6">
                    <div className="pmivr-label mb-1">
                      <label>Select Environment</label>
                    </div>
                    <select id="environment" name="environment" value={formik.values.environment} autoFocus className="pmivr-select"
                      onChange={(event) => {
                        setDeploymentEnvironment({ ...deploymentEnvironment, selectedEnvironment: event.target.value });
                        formik.handleChange(event);
                      }}>
                      {
                        deploymentEnvironment.environment.map((env) => {
                          return (
                            <option key={env.value} value={env.value} disabled={env.disabled}>
                              {env.text}
                            </option>
                          );
                        })
                      }
                    </select>
                  </div>
                </>
              )}
              <div id="search-box"
                className={deploymentEnvironment?.isDeploymentEnvironmentsExists
                  ? 'search-input props-custom-input col-md-6' : 'search-input props-custom-input col-md-12'}>
                <input type="text" required className="form-control pe-5 mt-2" name="businessCode"
                  disabled={(deploymentEnvironment?.isDeploymentEnvironmentsExists
                    && deploymentEnvironment.selectedEnvironment === '')} // disabled until the selection of environment is done
                  placeholder=" " // this is required as it will use mentioned label as its value              
                  onChange={formik.handleChange} value={formik.values.businessCode}></input>
                <label className="mt-2">Enter Business Code</label>
                <button className="pmivr-btn-transparent mt-2" type="submit">
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </div>
          </form>
          {/* display the recent search as per the environment selected.*/}
          {(
            <div id="recent-search">
              {recentlyUpdatedBusinessCode.length > 0 ? (
                <div className="text-center pmivr-text-primary mt-4 pt-3">
                  Recently Searched
                </div>
              ) : (
                <div className="saved-business-code-card border-dashed p-3 mt-4">
                  No recent search
                </div>
              )}
              <div className="text-center mt-2">
                {uiState.showRecentBusinessCodeLoader ? (
                  <div className="pmivr-loader loader-small mt-4"></div>
                ) : (
                  <></>
                )}
                {/* index is required for unique key  */}
                {recentlyUpdatedBusinessCode.map((data, index) => {
                  return (
                    <div key={index} className={`saved-business-code-card pmivr-card m-1 ${deploymentEnvironment?.isDeploymentEnvironmentsExists ? 'recently-updated-business-code-box-large' : 'recently-updated-business-code-box'}`}>
                      <button className={`card-body pmivr-btn-transparent ${deploymentEnvironment?.isDeploymentEnvironmentsExists ? 'p-2 fs-6 fw-bolder' : 'p-3'}`}
                        name={data?.businessCode} onClick={(event) => {
                          const selectedEnvironment = data?.environment;
                          // Update the deployment environment in local state
                          setDeploymentEnvironment({ ...deploymentEnvironment, selectedEnvironment });
                          // Call the function to get client flow states
                          getClientFlowStates({ businessCode: event.target.name, selectedEnvironment });
                        }}
                      >
                        {data?.businessCode}
                      </button>
                      {deploymentEnvironment?.isDeploymentEnvironmentsExists && <div class="text-muted">{data?.environmentDetails?.name}</div>}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <PmivrDialog showDialog={uiState.showConfirmDialog} closeDialog={() => setUiState({ ...uiState, showConfirmDialog: false })}
        title="Configure new client"
        message={
          <div className="p-2">
            {`"${formik.values.businessCode}" client not configured yet! Do you want to add "${formik.values.businessCode}"`}
          </div>
        }
        footer={
          <div className="d-flex">
            <div className="mx-2">
              <button className="pmivr-btn-secondary" type="button"
                onClick={() => setUiState({ ...uiState, showConfirmDialog: false })} style={{ padding: "0.7rem" }}>
                Cancel
              </button>
            </div>
            <div className="mx-2">
              <button className="pmivr-btn-app" type="submit" onClick={() => naviagateToClientFlows(formik.values.businessCode)}
                style={{ padding: "0.7rem" }}>
                Confirm
              </button>
            </div>
          </div>
        } />
    </>
  );
};

export default Home;