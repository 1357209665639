import React, { useContext, useState } from "react";
import { TAB_LIST } from "../../../constants/element";
import { ATTRIBUTES } from "../../../constants/attributes";
import { TOOLTIP, WEEKDAYS } from "../../../constants/messages";

import ElementService from "../../../services/element.service";
import { VoiceContext } from "../../../contexts/app-context";

import PmivrOverlayTrigger from '../../common/overlay-trigger/pmivr-overlay-trigger';
import PmivrLabel from "../../common/label/pmivr-label";

/**
 * Transfer call properties view in the diagram
 * @param {Object} props props data from parent component
 */
const TransferPropertiesView = () => {
  const { element, tabType } = useContext(VoiceContext);
  // Initialize with existing dates (split comma-separated string into an array)
  const [selectedDates, setSelectedDates] = useState(
    element.businessObject.get(ATTRIBUTES.TRANSFER_HOLIDAY)?.split(",") || []
  );

  /**
   * Update the property in the flow
   * @param {string} property
   * @param {Object} value
   */
  const updateProperty = (property, value) => {
    ElementService.updateElement(element, property, value);
  }

  /**
   * Update the common business hours in the flow
   * @param {Object} e event of type object
   */
  const updateCommonBusinessHours = (e) => {
    const isChecked = e.target.checked;
    updateProperty(ATTRIBUTES.TRANSFER_COMMON_BUSINESS_HOURS, isChecked);

    //if common business hours is not needed, clean start time and end time as well
    if (!isChecked) {
      updateProperty(ATTRIBUTES.TRANSFER_START_TIME, "");
      updateProperty(ATTRIBUTES.TRANSFER_END_TIME, "");
    }
  }

  /**
   * Adds a new date to the selected dates list.
   * Ensures the date is not already in the list before adding.
   * Updates the state and the corresponding property with the updated list of dates.
   * @param {Object} event - The event object from the input field.
   */
  const handleDateAdd = (event) => {
    const newDate = event.target.value; // Get the selected date
    if (newDate && !selectedDates.includes(newDate)) { // Ensure no duplicates
      const updatedDates = [...selectedDates, newDate]; // Add the new date to the list
      setSelectedDates(updatedDates); // Update state
      updateProperty(ATTRIBUTES.TRANSFER_HOLIDAY, updatedDates.join(",")); // Update property with comma-separated values
    }
    event.target.value = ""; // Reset the input field
  };

  /**
   * Removes a date from the selected dates list.
   * Updates the state and the corresponding property with the updated list of dates.
   * @param {string} dateToRemove - The date that should be removed from the list.
   */
  const handleDateRemove = (dateToRemove) => {
    const updatedDates = selectedDates.filter(date => date !== dateToRemove); // Filter out the removed date
    setSelectedDates(updatedDates); // Update state with the remaining dates
    updateProperty(ATTRIBUTES.TRANSFER_HOLIDAY, updatedDates.join(",")); // Update property
  };


  return (
    <>
      {
        (tabType === TAB_LIST)
          ? <button
            className="nav-link active"
            id="transfer"
            data-bs-toggle="tab"
            data-bs-target="#edit-transfer"
            type="button"
            role="tab"
            aria-controls="edit-transfer"
            aria-selected="false"
          >
            Transfer
          </button>
          : <>
            <div className="tab-pane fade show active" id="edit-transfer" role="tabpanel" aria-labelledby="nav-home-tab">
              <div className=" m-2 mt-3">
                <div className="form-group mb-2">
                  <PmivrLabel label="Transfer Number" tooltip={TOOLTIP.INFO.TRANSFER_NUMBER} />
                  <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.TRANSFER_NUMBER}>
                    <input
                      id="transferNumber"
                      name="transferNumber"
                      type="number"
                      className="form-control pmivr-input"
                      value={element.businessObject.get(ATTRIBUTES.TRANSFER_NUMBER) || ""}
                      onChange={(event) => { updateProperty(ATTRIBUTES.TRANSFER_NUMBER, event.target.value); }}
                      placeholder="Please enter the transfer number"
                    />
                  </PmivrOverlayTrigger>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-check pmivr-check-radio form-check-inline mt-4 mb-4">
                      <input
                        id="commonBusinessHours"
                        name="commonBusinessHours"
                        className="form-check-input radio"
                        type="checkBox"
                        checked={
                          element.businessObject.get(ATTRIBUTES.TRANSFER_COMMON_BUSINESS_HOURS) === "true" || element.businessObject.get(ATTRIBUTES.TRANSFER_COMMON_BUSINESS_HOURS) === true
                            ? true
                            : false
                        }
                        onChange={(e) => updateCommonBusinessHours(e)}
                      />
                      <label className="form-check-label ">
                        Common Business Hours<br></br> Standard Business hours ?
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <PmivrLabel label="Business Start Time" tooltip={TOOLTIP.INFO.BUSINESS_START_TIME} />
                  <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.BUSINESS_START_TIME}>
                    <input
                      id="startTime"
                      name="startTime"
                      className="form-control pmivr-input"
                      value={element.businessObject.get(ATTRIBUTES.TRANSFER_START_TIME) || ""}
                      onChange={(event) => { updateProperty(ATTRIBUTES.TRANSFER_START_TIME, event.target.value); }}
                      placeholder="Enter Business Start Time (hhmm)"
                      readOnly={!element.businessObject.get(ATTRIBUTES.TRANSFER_COMMON_BUSINESS_HOURS)}
                    />
                  </PmivrOverlayTrigger>
                </div>
                <div className="form-group mt-4 mb-4">
                  <PmivrLabel label="Business End Time" tooltip={TOOLTIP.INFO.BUSINESS_END_TIME} />
                  <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.BUSINESS_END_TIME}>
                    <input
                      id="endTime"
                      name="endTime"
                      className="form-control pmivr-input"
                      value={element.businessObject.get(ATTRIBUTES.TRANSFER_END_TIME) || ""}
                      onChange={(event) => { updateProperty(ATTRIBUTES.TRANSFER_END_TIME, event.target.value); }}
                      placeholder="Enter Business End Time (hhmm)"
                      readOnly={!element.businessObject.get(ATTRIBUTES.TRANSFER_COMMON_BUSINESS_HOURS)}
                    />
                  </PmivrOverlayTrigger>
                </div>
                {[...Array(7)].map((x, i) => (
                  <div key={i}>
                    <div className="row">
                      <div className="form-group col-md-2 d-flex justify-content-center align-items-center">
                        <PmivrOverlayTrigger tooltip={WEEKDAYS.FULL_NAME[i]}>
                          <span className=" pmivr-input mt-1 transfer-weekdays d-flex justify-content-center align-items-center">{WEEKDAYS.INITIAL[i]}</span>
                        </PmivrOverlayTrigger>
                      </div>
                      <div className="form-group mb-4 col-md-5">
                        <label className="pmivr-label pb-1">Start Time</label>
                        <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.TRANSFER_CALL_START_TIME}>
                          <input
                            key={"start" + i}
                            id={"startTimeDay" + i}
                            name={"startTimeDay" + i}
                            className="form-control pmivr-input custom-time-input"
                            value={element.businessObject.get(ATTRIBUTES.TRANSFER_START_TIME + `${i}`) || ""}
                            onChange={(event) => { updateProperty(ATTRIBUTES.TRANSFER_START_TIME + `${i}`, event.target.value); }}
                            placeholder={`Enter start time Day ${i}`}
                            disabled={element.businessObject.get(ATTRIBUTES.TRANSFER_COMMON_BUSINESS_HOURS)}
                            type="text"
                            onFocus={(e) => { e.target.type = 'time'; e.target.value = ""; }}
                            onBlur={(e) => { if (!e.target.value) { e.target.type = 'text'; } }}
                          />
                        </PmivrOverlayTrigger>
                      </div>
                      <div className="form-group mb-4 col-md-5">
                        <div className="pmivr-label">
                          <label className="pmivr-label pb-1" >End Time</label>
                        </div>
                        <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.TRANSFER_CALL_END_TIME}>
                          <input
                            key={"end" + i}
                            id={"endTimeDay" + i}
                            name={"endTimeDay" + (i + 1)}
                            className="form-control pmivr-input custom-time-input"
                            value={element.businessObject.get(ATTRIBUTES.TRANSFER_END_TIME + `${i}`) || ""}
                            onChange={(event) => { updateProperty(ATTRIBUTES.TRANSFER_END_TIME + `${i}`, event.target.value); }}
                            placeholder={"Enter end time Day " + i}
                            disabled={element.businessObject.get(ATTRIBUTES.TRANSFER_COMMON_BUSINESS_HOURS)}
                            type="text"
                            onFocus={(e) => { e.target.type = 'time'; e.target.value = ""; }}
                            onBlur={(e) => { if (!e.target.value) { e.target.type = 'text'; } }}
                          />
                        </PmivrOverlayTrigger>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="form-group mt-4 mb-4">
                  <PmivrLabel label="Holidays List" tooltip={TOOLTIP.INFO.HOLIDAYS_LIST} />
                  <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.HOLIDAYS_LIST}>
                    <div className="d-flex justify-content-center transfer-holiday-list position-relative">
                      <div className="transfer-select-date">
                        {selectedDates.length > 0 ?
                          selectedDates?.map((date, index) => (
                            <span key={index} className="pmivr-input">
                              {date}
                              <span onClick={() => handleDateRemove(date)} className="remove-date">
                                <i class="bi bi-x-lg"></i>
                              </span>
                            </span>
                          )) :
                          <label className="d-flex justify-content-center align-items-center">Please select dates</label>
                        }
                      </div>
                      <input
                        id="transferHoliday"
                        name="transferHoliday"
                        type="date"
                        className="position-absolute date-picker"
                        onChange={handleDateAdd}
                        placeholder={"Enter Holidays list(Comma seperated) Format : MMddyyyy"}
                      />
                      <i class="bi bi-calendar-date position-absolute date-picker-icon"></i>
                    </div>
                  </PmivrOverlayTrigger>
                </div>
                <div className="form-group mt-4 mb-4">
                  <PmivrLabel label="Zero Out Voice File" tooltip={TOOLTIP.INFO.ZERO_OUT_VOICE_FILE} />
                  <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.ZERO_OUT_VOICE_FILE}>
                    <input
                      id="zeroOutVoiceFile"
                      name="zeroOutVoiceFile"
                      className="form-control pmivr-input"
                      value={element.businessObject.get(ATTRIBUTES.TRANSFER_ZERO_OUT_VOICE_FILE) || ""}
                      onChange={(event) => { updateProperty(ATTRIBUTES.TRANSFER_ZERO_OUT_VOICE_FILE, event.target.value); }}
                      placeholder="Enter Zero Out Voice File"
                    />
                  </PmivrOverlayTrigger>
                </div>
                <div className="form-group mt-4 mb-4">
                  <PmivrLabel label="Outside of Business Hours Voice File" tooltip={TOOLTIP.INFO.OUTSIDE_OF_BUSINESS_HOURS} />
                  <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.OUTSIDE_OF_BUSINESS_HOURS}>
                    <input
                      id="outsideBusinessHoursVoiceFile"
                      name="outsideBusinessHoursVoiceFile"
                      className="form-control pmivr-input mt-1"
                      value={element.businessObject.get(ATTRIBUTES.TRANSFER_OUTSIDE_BUSINESS_HOUR_VOICE_FILE) || ""}
                      onChange={(event) => { updateProperty(ATTRIBUTES.TRANSFER_OUTSIDE_BUSINESS_HOUR_VOICE_FILE, event.target.value); }}
                      placeholder="Enter OutSide of Business Hours Voice File"
                    />
                  </PmivrOverlayTrigger>
                </div>
              </div>
            </div >
          </>
      }
    </>
  )
}

export default TransferPropertiesView;