class StringUtil {

    /**
     * Convert the given value to boolean value
     * @param {string} value value to be converted
     * @returns {boolean} (true / false)
     */
    static toBoolean(value) {
        return (String(value) === 'true');
    }
}

export default StringUtil;