import { Formik, Form, Field, ErrorMessage } from "formik";
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { MESSAGES, TOOLTIP } from "../../../constants/messages";
import { REGEX } from "../../../config/config";

import PmivrLabel from "../../../components/common/label/pmivr-label";

import AppUtil from "../../../util/app.util";
import DateUtil from "../../../util/date.util";

/**
 * Component to select range of date and time, used to filter data on basis of date-time range
 * @param {{Function}} onChange Function which is called when date-time range is changed or enter key is pressed
 * @returns {JSX.Element} A date and time range picker form with fields for selecting start and end date-time range.
 */
const PmivrDateTimeRangePicker = ({ onChange }) => {
    // Validation schema for Formik using Yup
    const validationSchema = Yup.object().shape({
        startDate: Yup.string()
            .matches(REGEX.DATE_TIME, `Start Date must be in the format ${MESSAGES.DATE_TIME_FORMAT}`),
        endDate: Yup.string()
            .matches(REGEX.DATE_TIME, `End Date must be in the format ${MESSAGES.DATE_TIME_FORMAT}`)
            .test('isAfterStart', 'End Date must be after Start Date', function (endDate) {
                const { startDate } = this.parent;

                // If either date is missing, skip validation
                if (!startDate || !endDate) return true;

                // Parse the dates to date object
                const start = DateUtil.parseDateString(startDate);
                const end = DateUtil.parseDateString(endDate);

                // Return true if end date is after start date
                return end > start;
            })
    });

    return (
        <Formik
            initialValues={{ startDate: "", endDate: "" }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                onChange(values);
            }} >
            {({ values, setFieldValue  }) => (
                <Form className="row ps-2 mt-3">
                    <div className="col-sm-2 pmivr-relative">
                        <PmivrLabel label="Start Date & Time" tooltip={TOOLTIP.INFO.CALL_LOGS.START_DATE} cssClass="my-0" />
                        <Field name="startDate" type="text" className="form-control pmivr-input"
                            placeholder={`(${MESSAGES.DATE_TIME_FORMAT})`}
                            onChange={(e) => {
                                setFieldValue("startDate", e.target.value);
                                onChange({ ...values, startDate: e.target.value });
                            }}
                            onKeyDown={(e) => AppUtil.isEnterKey(e) ? onChange(values, true) : null } />
                        <ErrorMessage name="startDate" component="div" className="field-error" />
                    </div>

                    <div className="col-sm-2 pmivr-relative">
                        <PmivrLabel label="End Date & Time" tooltip={TOOLTIP.INFO.CALL_LOGS.END_DATE} cssClass="my-0" />
                        <Field name="endDate" type="text" className="form-control pmivr-input"
                            placeholder={`(${MESSAGES.DATE_TIME_FORMAT})`}
                            onChange={(e) => {
                                setFieldValue("endDate", e.target.value);
                                onChange({ ...values, endDate: e.target.value });
                            }}
                            onKeyDown={(e) => AppUtil.isEnterKey(e) ? onChange(values, true) : null} />
                        <ErrorMessage name="endDate" component="div" className="field-error" />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

PmivrDateTimeRangePicker.propTypes = {
    // Function which is called in the parent component on changing the date time range
    onChange: PropTypes.func
}

export default PmivrDateTimeRangePicker;
