import { useState } from "react";
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from "formik";

import { MESSAGES, TOOLTIP } from "../../../../constants/messages";
import { TASK_ICONS } from "../../../../constants/css-classes";

import PmivrOverlayTrigger from "../../../../components/common/overlay-trigger/pmivr-overlay-trigger";
import PmivrLabel from "../../../../components/common/label/pmivr-label";
import PmivrTooltip from "../../../../components/common/tooltip/pmivr-tooltip";

/**
 * Form for uploading xml file for draft version
 * @param {Object} props Properties from parent component
 * @returns {React.Component} Html element to render
 */
const UploadXmlFile = (props) => {
    const { flowInfo, uploadFlowXml, closeAction } = props;

    // ui state object having following flags
    // disabled: flag to enable / disable the button
    // message: object having message text and error specifying flag
    const [uiState, setUiState] = useState({
        disabled: false, message: { text: '', isError: false }
    });

    // validating the formik fields
    const validate = Yup.object({
        name: Yup.string().required(MESSAGES.ERR.FIELD_REQUIRED),
        file: Yup.mixed()
            .required(MESSAGES.UPLOAD_XML_FILE)
            .test('fileType', MESSAGES.ERR.INVALID_XML_FILE, (value) => {
                // Check if the file is xml
                return value?.type === 'text/xml';
            })
    });

    // formik values for form
    const formik = useFormik({
        initialValues: { name: '', comments: '', file: null, isMigrateFlowXml: false },
        validationSchema: validate,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    // to set the field value of file on change
    const { setFieldValue } = formik;

    /**
     * Handles submit of formik form and uploading of xml file
     * @param {{file, name, comments}} values - values from formik
     */
    const handleSubmit = async (values) => {
        setUiState({ ...uiState, disabled: true, formDisabled: true });
        try {
            // upload the flow xml to server and create a draft version for the xml
            await uploadFlowXml(values);
            setUIStateProps(true, { text: MESSAGES.XML_FILE_UPLOADED, isError: false });
            // giving timeout to remain on the same screen for displaying message
            setTimeout(() => {
                closeAction();
            }, 1000);
        } catch (err) {
            setUiState({ ...uiState, disabled: false, formDisabled: false });
            setUIStateProps(false, { text: MESSAGES.SOMETHING_WENT_WRONG, isError: true });
        }
    }

    /**
     * Updating the ui state props in the state
     * @param {Boolean} disabled flag to disable the element like button
     * @param {{ text , isError }} message text message and flag specifying error message
     */
    const setUIStateProps = (disabled = false, message = { text: '', isError: false }) => {
        setUiState((prevState) => {
            const newState = { ...prevState };
            newState.disabled = disabled;
            newState.message.text = message.text;
            newState.message.isError = message.isError;
            return newState;
        });
    }

    return (
        <div className="pmivr-card card-secondary pmivr-upload-file-dialog">
            <div className={uiState.message.isError ? "field-error text-center" : "field-success text-center"}>{uiState.message?.text}</div>
            <div className="container mb-2">
                {MESSAGES.UPLOAD_XML_FILE_INFO}
            </div>
            <form onSubmit={formik.handleSubmit} disabled={uiState.formDisabled}>
                <div className="pmivr-container">
                    <div className="wrapper p-3 pt-0">
                        <div className="mb-2 remove-arrows">
                            <PmivrLabel label="Name" tooltip={TOOLTIP.INFO.DRAFT.NAME} cssClass="mt-2 pmivr-required-label" />
                            <PmivrOverlayTrigger tooltip={TOOLTIP.INFO.DRAFT.NAME}>
                                <input type="text" name="name" id="name" value={formik.values.name || ""}
                                    placeholder="Enter Name" className="form-control pmivr-input" title="" autoFocus
                                    onChange={(event) => {
                                        // remove the error message when user again enters the name after seeing the error message
                                        setUIStateProps(false, { text: '', isError: false });
                                        formik.handleChange(event);
                                    }} />
                            </PmivrOverlayTrigger>
                            {formik.touched.name && formik.errors.name && (
                                <div className='field-error text-start'>{formik.errors.name}</div>
                            )}

                            <PmivrLabel label="Comments" tooltip={TOOLTIP.INFO.DRAFT.COMMENTS} cssClass="mt-2" />
                            <PmivrOverlayTrigger tooltip={TOOLTIP.INFO.DRAFT.COMMENTS}>
                                <textarea type="text" name="comments" id="comments" value={formik.values.comments || ""} rows="3"
                                    placeholder="Enter Comments" className="form-control pmivr-input" onChange={(event) => {
                                        // remove the error message when user again enters the comments after seeing the error message
                                        setUIStateProps(false, { text: '', isError: false });
                                        formik.handleChange(event);
                                    }} />
                            </PmivrOverlayTrigger>
                            {formik.touched.comments && formik.errors.comments && (
                                <div className='field-error text-start mb-3'>{formik.errors.comments}</div>
                            )}

                            <div className="form-check pmivr-check-radio mb-2 mt-2">
                                <input className="form-check-input" type="checkbox" id="is-skip-if-single-option"
                                    checked={formik.values.isMigrateFlowXml}
                                    onChange={(event) => {
                                        setFieldValue("isMigrateFlowXml", event.target.checked);
                                    }}
                                />
                                <label className="form-check-label">Is Latest Flow Migration</label>
                                <PmivrTooltip message={TOOLTIP.INFO.IS_MANUAL_MIGRATION}>
                                    <i className={`${TASK_ICONS.DISPLAY_INFO} check-box-info-icon`}></i>
                                </PmivrTooltip>
                            </div>

                            <div className="pmivr-select-file-input float-start mt-3">
                                <label htmlFor={`file-${flowInfo.flowTypeId}`}
                                    title="Select XML file only"
                                    className="custom-file-upload pmivr-btn-secondary">
                                    Select <i className="bi bi-filetype-xml"></i> File
                                </label>
                                <input type="file" accept=".xml"
                                    id={`file-${flowInfo.flowTypeId}`}
                                    name="file"
                                    onChange={(event) => {
                                        setFieldValue("file", event.currentTarget.files[0]);
                                        // resetting the event target value as some browsers 
                                        // did not clear the event target for input file
                                        event.target.value = null;
                                    }} />
                                <span className="file-info-text">
                                    {formik?.values?.file?.name}
                                </span>
                                {formik.touched.file && formik.errors.file && (
                                    <div className='field-error text-start d-block'>{formik.errors.file}</div>
                                )}
                            </div>

                            <div className="float-end mt-3">
                                <button type="submit" className="pmivr-btn-app float-end mx-2" disabled={uiState.disabled} >
                                    Upload
                                </button>
                                <button type="button" className="pmivr-btn-secondary float-end" onClick={closeAction} >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

UploadXmlFile.propTypes = {
    // closing the user dialogue
    closeAction: PropTypes.func,
    // function that uploads xml to server, and create a draft version
    uploadFlowXml: PropTypes.func,
    // flow details such as flow name, flowTypeId etc.
    flowInfo: PropTypes.object
}

export default UploadXmlFile;