import { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { TOOLTIP } from "../../../constants/messages";
import { PmivrDialog } from "../dialog/pmivr-dialog";
import EditOptionModal from "../../properties-panel/single-voice-file-option/EditOptionModal";
import PmivrOverlayTrigger from "../overlay-trigger/pmivr-overlay-trigger";

/**
 * Add/Edit the values mapped to options
 * @param {{ optionAndVal, isOptionAndValStartFromZero, showOptionMenu, handleCloseOptionMenu,
 * updateOptionAndValues, removeOption, addOption, handleSaveOptionMenu, 
 * setShowOptionMenu }} props props data from parent component
 * @returns  {React.Component} Html element to render
 */
const OptionValueMapping = ({ optionAndVal, isOptionAndValStartFromZero, showOptionMenu, handleCloseOptionMenu,
    updateOptionAndValues, removeOption, addOption, handleSaveOptionMenu, setShowOptionMenu }) => {
    const [activeAccordionKey, setActiveAccordionKey] = useState(null);
    const [showAddOption, setShowAddOption] = useState(null);

    return (
        <>
            <Accordion activeKey={activeAccordionKey} className="pmivr-accordion"
                onSelect={(selectedKey) => setActiveAccordionKey(selectedKey)
                } flush>
                <Accordion.Item eventKey={"validation-user-input"} className="mt-3 accordion-voice-item">
                    <Accordion.Header>
                        <span className="pmivr-accordian-tab">
                            Mapping Option Values
                        </span>
                    </Accordion.Header>
                    <Accordion.Body className="p-2 pt-0">
                        {Object.keys(optionAndVal).map((key, index) => {
                            return (
                                <div key={index}>
                                    <div className="row mt-2 mb-2">
                                        <div className="col-sm-3">
                                            <select id={`file_option_${index}`} className="pmivr-select" aria-label="Default select example" disabled={true}>
                                                <option selected>
                                                    {(isOptionAndValStartFromZero) ? (Number(optionAndVal[index][0]) + 1) : (optionAndVal[index][0])}
                                                </option>
                                            </select>
                                        </div>
                                        <div className="col-sm-9 text-center">
                                            <PmivrOverlayTrigger tooltip={optionAndVal[index][1] || "Please enter a valid value"}>
                                                <input className="form-control pmivr-input pmivr-disabled" id={`file_value_${index}`} value={optionAndVal[index][1]} readOnly={true} type="text" required />
                                            </PmivrOverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="d-flex justify-content-end ">
                            <PmivrOverlayTrigger tooltip={TOOLTIP.INFO.EDIT_OPTION_MAPPINGS}>
                                <button className=" mt-1 p-2 pmivr-btn-app pmivr-btn-rounded-circle rounded-circle pmivr-add-option"
                                    onClick={(event) => { setShowOptionMenu(true); setShowAddOption(true); }}>
                                    <i>+</i>
                                </button>
                            </PmivrOverlayTrigger>
                            <PmivrOverlayTrigger tooltip={TOOLTIP.INFO.EDIT_OPTION_MAPPINGS}>
                                <button className=" mt-1 p-2 pmivr-btn-app pmivr-btn-rounded-circle rounded-circle ms-2"
                                    onClick={(event) => { setShowOptionMenu(true); setShowAddOption(false); }}>
                                    <i className="bi bi-pencil"> </i>
                                </button>
                            </PmivrOverlayTrigger>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <PmivrDialog showDialog={showOptionMenu} closeDialog={handleCloseOptionMenu} title={`Add Options`}
                isOptionAndValStartFromZero={isOptionAndValStartFromZero} removeOption={removeOption}
                saveDialogChanges={handleSaveOptionMenu}
                message={<EditOptionModal optionAndVal={optionAndVal}
                    updateOptionAndValues={updateOptionAndValues} addOption={addOption} showAddOption={showAddOption} />} />
        </>
    )

}

OptionValueMapping.prototype = {
    optionAndVal: PropTypes.object,
    isOptionAndValStartFromZero: PropTypes.bool,
    showOptionMenu: PropTypes.bool,
    handleCloseOptionMenu: PropTypes.func,
    updateOptionAndValues: PropTypes.func,
    removeOption: PropTypes.func,
    addOption: PropTypes.func,
    handleSaveOptionMenu: PropTypes.func,
    setShowOptionMenu: PropTypes.bool
}

export default OptionValueMapping;