// default language 
export const VOICE_FILE_DEFAULT_LANGUAGE = "en"

//  voice files upload types
export const VOICE_FILE_UPLOAD_TYPE = {
  // library file tat user selects
  LIBRARY: "libraryFile",
  // Tts text to speech generated using gender and playSpeed
  TTS: "tts",
  // type of file user uploads like .wav file
  UPLOAD: "upload",
  // variable type, prompt will be played based on type of variable and value of variable
  VARIABLE: "variable"
};

// it is display name for how voice file is configured 
export const VOICE_FILE_UPLOAD_TYPE_NAME = {
  LIBRARYFILE: "Library File",
  TTS: "Text To Speech",
  UPLOAD: "Upload File",
};

// voice file Type constant
export const VOICE_FILE_TYPE = { VALID: "VALID", INVALID: "INVALID" };

// voice file play by gender for TTS
export const VOICE_GENDER = {
  MALE: "male",
  FEMALE: "female"
};

// voice file play speed rates for tts
// speed rate are required to give back support to speed having values like "normal","slow"
export const VOICE_FILE_PLAY_SPEED_RATE = {
  SLOW: 70,
  NORMAL: 80,
  FAST: 100
};

// voice file play speed for TTS
export const VOICE_FILE_PLAY_SPEED = {
  SLOW: "slow",
  NORMAL: "normal",
  FAST: "fast"
};

// it is the display name for languages
export const VOICE_FILE_LANGUAGE_NAME = {
  EN: "English",
  ES: "Spanish",
  FR: "French"
};

// voice files filter keys for voice files page
export const VOICE_FILES_FILTER_KEYS = {
  STEP: "step",
  PROCESS: "process",
  TASK_TYPE: "taskType",
};

// voice types info used for configuration for voice in tts
/**
 * voice types info used for configuration for voice in tts
 * name to specify for voice id in tts ms , based on language and gender
 */
export const TTS_VOICE_TYPES_INFO = [
  { name: "Matthew", language: "en", gender: "male", languageCode: "en-US" },
  { name: "Joanna", language: "en", gender: "female", languageCode: "en-US" },
  { name: "Lupe", language: "es", gender: "female", languageCode: "es-US" },
  { name: "Karl", language: "es", gender: "male", languageCode: "es-US" },
  { name: "Lupe", language: "fr", gender: "female", languageCode: "fr-FR" },
  { name: "Karl", language: "fr", gender: "male", languageCode: "fr-FR" },
];