import React, { useEffect, useState } from "react";
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector } from "react-redux";

import { APP_PAGES } from "./constants/app-pages";
import { MESSAGES } from "./constants/messages.js";

import StorageUtil from "./util/storage.util.js";
import AppUtil from "./util/app.util.js";

import PmivrAppLayout from "./components/common/layouts/pmivr-app-layout";
import PmivrAuthorization from "./components/common/authorization/pmivr-authorization.js";
import PmivrLoader from "./components/common/loader/pmivr-loader";
import SessionExpireDialog from "./components/common/session-expire/session-expire.js";

import Diagram from "./pages/diagram/diagram";
import Login from "./pages/login/login.js";
import Home from "./pages/home/home.js";
import ClientFlows from "./pages/client-flows/client-flows.js";
import Variables from "./pages/variables/variables.js";
import Wizard from "./pages/wizard/wizard.js";
import Users from "./pages/users/users";
import Numbers from "./pages/numbers/numbers";
import DraftOptions from "./pages/draft-options/draft-options.js";
import Settings from "./pages/settings/settings";
import Clients from "./pages/clients/clients.js";
import Templates from "./pages/templates/templates";
import ApiKeys from "./pages/api-keys/api-keys";
import Logs from "./pages/call-logs/logs.js";
import ListVoiceFilesComponent from "./pages/list-voice-file/list-voice-files";
import ErrorPage from "./pages/error-page/error-page";
import DeploymentEnvironments from "./pages/deployment-environment/deployment-environments.js";

import AuthService from "./services/auth.service";
import UserService from "./services/user.service";

function App() {
  // getting latest value from redux for loader
  const { showLoader } = useSelector(state => state.uiState);
  const { sessionCheckInfo } = useSelector(state => state.user);
  // before loading routes there can be conditions to check like setting auth token from urls
  const [loadRoutes, setLoadRoutes] = useState(false);

  useEffect(() => {
    // check if on starting on login check is initiated
    if (sessionCheckInfo?.isSessionCheckInitiated) {
      // if already session Expiring dialog is shown and still user does not want to fill password
      if (sessionCheckInfo?.isSessionExpiring) {
        StorageUtil.clearStorage();
        AppUtil.navigateTo(APP_PAGES.LOGIN);
      } else {
        initiateSessionCheck();
      }
    }
  }, [sessionCheckInfo?.isSessionCheckInitiated]);

  useEffect(() => {
    onInit();
  }, []);

  // whether session is valid or not. If not, then we navigate login
  const onInit = async () => {
    const currentUrl = window.location.href;
    // set the auth token if token is in url
    handleUrlAuthToken(currentUrl);
    // all conditions before loading of app is done, now it can load routes
    setLoadRoutes(true);
    // dont check session on login page
    if (!currentUrl.endsWith(APP_PAGES.LOGIN)) {
      await AuthService.validateSession();
    }
  }

  /**
   * If current url containes the token field (in case of redirection from other app like interactive desinger)
   * then it sets the token in current user object
   */
  const handleUrlAuthToken = (url) => {
    // Extract the query string from the URL
    const queryString = url.split('?')[1];

    // Create a URLSearchParams object to parse the query string
    const queryParams = new URLSearchParams(queryString);

    // Get the value of the "token" query parameter
    const token = queryParams.get('token');
    if (token) {
      // set the token validate session will fill the complete user object
      UserService.setCurrentUser({ token });
    }
  }

  /**
   * Check the session if it is going to expire then show the pop up to login again
   */
  const initiateSessionCheck = () => {
    const currentUser = UserService.getCurrentUser();
    // get the expire time at which token will get expire
    const expirationTime = currentUser?.expiresAt;
    // subtract the 3 mins from expiry time
    const showSessionDialogTime = expirationTime - (3 * 60);
    // get the current time to calculate the remining time left for dialog open
    const currentTime = Math.floor(Date.now() / 1000);

    if (expirationTime) {
      if (expirationTime > 0) {
        setTimeout(() => {
          // update the redux to store if we are opening the dialog for session
          AuthService.setSessionCheckInfo({ isSessionCheckInitiated: true, isSessionExpiring: true });
          // we poput the session dialog to enter password just 3 mins before the session timeout
          // multiply with 1000 to convert to milliseconds
        }, (showSessionDialogTime - currentTime) * 1000);
      }
    }
  }

  return (
    <div className="pmivr-app">
      {
        loadRoutes &&
        <Router>
          <SessionExpireDialog initiateSessionCheck={initiateSessionCheck} />
          <PmivrLoader showLoader={showLoader} />
          <Routes>
            <Route path={APP_PAGES.DEFAULT} exact element={<PmivrAppLayout showFooter={true}>{<Home />}</PmivrAppLayout>}>
            </Route>
            <Route path={APP_PAGES.LOGIN} exact element={<Login />}>
            </Route>
            {/* NOTE string concatenation was not working */}
            <Route path='/diagram/:businessCode/:status/:versionId' exact
              element={<PmivrAppLayout showLeftBar={true} showFooter={false}><Diagram /></PmivrAppLayout>}>
            </Route>
            <Route path={APP_PAGES.HOME} exact element={<PmivrAppLayout showFooter={true}>{<Home />}</PmivrAppLayout>}></Route>
            <Route path={APP_PAGES.USERS} exact element={<PmivrAuthorization>{<PmivrAppLayout showFooter={true}>{<Users />}</PmivrAppLayout>}</PmivrAuthorization>}></Route>
            <Route path={APP_PAGES.NUMBERS} exact element={<PmivrAuthorization>{<PmivrAppLayout showFooter={true}>{<Numbers />}</PmivrAppLayout>}</PmivrAuthorization>}></Route>
            <Route path={APP_PAGES.TEMPLATES} exact element={<PmivrAuthorization>{<PmivrAppLayout showFooter={true}>{<Templates />}</PmivrAppLayout>}</PmivrAuthorization>}></Route>
            <Route path={APP_PAGES.API_KEYS} exact element={<PmivrAuthorization>{<PmivrAppLayout showFooter={true}>{<ApiKeys />}</PmivrAppLayout>}</PmivrAuthorization>}></Route>
            <Route path={APP_PAGES.CLIENTS} exact element={<PmivrAuthorization>{<PmivrAppLayout showFooter={true}>{<Clients />}</PmivrAppLayout>}</PmivrAuthorization>}></Route>
            <Route path={APP_PAGES.DRAFT_OPTIONS} exact element={<PmivrAuthorization>{<PmivrAppLayout showFooter={true}>{<DraftOptions />}</PmivrAppLayout>}</PmivrAuthorization>}></Route>
              <Route path={APP_PAGES.DEPLOYMENT_ENVIRONMENTS} exact element={<PmivrAuthorization>{<PmivrAppLayout showFooter={true}>{<DeploymentEnvironments />}</PmivrAppLayout>}</PmivrAuthorization>}></Route>
            <Route path={APP_PAGES.LOGS} exact element={<PmivrAuthorization>{<PmivrAppLayout showFooter={true}>{<Logs />}</PmivrAppLayout>}</PmivrAuthorization>}></Route>
            <Route path={APP_PAGES.SETTINGS} exact element={<PmivrAuthorization>{<PmivrAppLayout showFooter={true}>{<Settings />}</PmivrAppLayout>}</PmivrAuthorization>}></Route>
            <Route path={APP_PAGES.VARIABLES} exact element={<PmivrAppLayout showLeftBar={true}>{<Variables />}</PmivrAppLayout>}>
            </Route>
            <Route path={`${APP_PAGES.CLIENT_FLOWS}/:id`} exact element={<PmivrAppLayout showFooter={true}>{<ClientFlows />}</PmivrAppLayout>}>
            </Route>
            <Route path={`${APP_PAGES.WIZARD}/:businessCode`} exact element={<Wizard />}>
            </Route>
            <Route path={APP_PAGES.LIST_VOICE_FILE} exact
              element={<PmivrAppLayout showLeftBar={true}>{<ListVoiceFilesComponent />} </PmivrAppLayout>}>
            </Route>
            {/* redirect to 404 page if url does not matches any of the defined routes  */}
            <Route path="*" element={<ErrorPage code={"404"} msg={MESSAGES.PAGE_NOT_FOUND} />} /></Routes>
        </Router>
      }
    </div >
  );
}

export default App;